/* eslint-disable complexity */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import {
  LandingPageBodyTextContent,
  LandingPageBookADemo,
  LandingPageButton,
  LandingPageLetsTalk,
  LandingPageStrongNetwork,
  LandingPageValuePropSelector,
  LandingValuePropCard,
  ResponsiveLandingPageHero,
  ShipperCarrierSplitValueProps,
  Testimonials,
} from '../components';
import type { NextPageProps, TNextRedirect, TPageId } from '../types';
import { useFooterPalette, useHeaderPalette } from '../hooks';
import { useHomeStyles, useLandingPageMarginStyles } from '../styles';
import BlueRoad from '../public/assets/images/landingPage/landing-page-blue-road.svg';
import CardImage1 from '../public/assets/images/landingPage/landing-page-value-prop-1.svg';
import CardImage2 from '../public/assets/images/landingPage/landing-page-value-prop-2.svg';
import CardImage4 from '../public/assets/images/landingPage/landing-page-value-prop-4.svg';
import classnames from 'classnames';
import Cloud from '../public/assets/images/landingPage/cloud.svg';
import { EUserTypeV1 } from '@truxweb/schemas';
import Gear from '../public/assets/images/landingPage/landing-page-gear.svg';
import { GetServerSidePropsContext } from 'next';
import { getTruxwebServerSideProps } from '../pageProps';
import Image from 'next/image';
import Man from '../public/assets/images/landingPage/landing-page-man.svg';
import React from 'react';
import { ResponsiveBasePage } from '../pageTemplates';
import { useTranslation } from 'next-i18next';

const PAGE_ID: TPageId = 'home';
const PAGE_LANGUAGE = null;
const REQUIRED_NAMESPACES = [
  'common',
  'select',
  'index',
  PAGE_ID,
  'shipments',
  'account',
  'loading',
];

export const Home = (): JSX.Element => {
  const classes = useHomeStyles();
  const margins = useLandingPageMarginStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('xl'));
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.lg));
  const isSmallTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTabletOrLower = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isTablet = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.md, theme.breakpoints.values.lg)
  );
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isManCentered = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm - 92));

  useFooterPalette('dark');
  useHeaderPalette('dark');

  return (
    <ResponsiveBasePage isHeaderTransparent={true} pageId={PAGE_ID}>
      {({ handleOpenSignup }): JSX.Element => (
        <Grid className={classes.container} container direction="column">
          <Grid item>
            <ResponsiveLandingPageHero />
          </Grid>
          {/* BODY TEXT, MAN AND FOUR CARDS*/}
          <Grid className={margins.landingPageMargins} container item>
            {!isSmallTablet && <Grid className={classes.gridMargin} item xs={1} />}
            {isWide && <Grid item xs={1} />}
            <Grid
              alignItems={isWide ? 'flex-start' : 'center'}
              className={classes.bodyCopyContainer}
              container
              direction="column"
              item
              md
              xs={12}
            >
              <Grid
                className={classes.greatForCompaniesText}
                container
                item
                justifyContent="center"
                spacing={2}
                wrap="wrap"
              >
                <LandingPageBodyTextContent />
              </Grid>
              <Grid container item justifyContent={isManCentered ? 'center' : 'flex-end'}>
                <Box mb={-2} mr={isTablet || isDesktop ? 8 : 4} mt={isMobile ? -1 : -4}>
                  <Man height={isDesktop ? '329px' : '201px'} width={isDesktop ? 534 : '326px'} />
                </Box>
              </Grid>
              <Grid
                className={classes.fourCardsWrap}
                container
                item
                justifyContent="center"
                spacing={3}
                wrap="wrap"
              >
                {!isSmallTablet && (
                  <>
                    <Box className={classes.cloudOne}>
                      <Cloud height={'100%'} width={'100%'} />
                    </Box>
                    <Box className={classes.cloudTwo}>
                      <Cloud height={'100%'} width={'100%'} />
                    </Box>
                    <Box className={classes.cloudThree}>
                      <Cloud height={'100%'} width={'100%'} />
                    </Box>
                  </>
                )}
                <Grid className={classes.valuePropCard} item xs={isSmallTablet ? 12 : 6}>
                  <LandingValuePropCard
                    cardImage={<CardImage1 height={'100%'} width={'100%'} />}
                    subtitle={t(`common:landingValueProp-1-subtitle`)}
                    title={t(`common:landingValueProp-1-title`)}
                    valueProp1={t(`common:landingValueProp-1-vp1`)}
                    valueProp2={t(`common:landingValueProp-1-vp2`)}
                    valueProp3={t(`common:landingValueProp-1-vp3`)}
                    variant={isManCentered ? 'dark' : 'light'}
                  />
                </Grid>
                <Grid className={classes.valuePropCard} item xs={isSmallTablet ? 12 : 6}>
                  <LandingValuePropCard
                    cardImage={<CardImage2 height={'100%'} width={'100%'} />}
                    subtitle={t(`common:landingValueProp-2-subtitle`)}
                    title={t(`common:landingValueProp-2-title`)}
                    valueProp1={t(`common:landingValueProp-2-vp1`)}
                    valueProp2={t(`common:landingValueProp-2-vp2`)}
                    valueProp3={t(`common:landingValueProp-2-vp3`)}
                    variant={isManCentered ? 'light' : 'dark'}
                  />
                </Grid>
                <Grid className={classes.valuePropCard} item xs={isSmallTablet ? 12 : 6}>
                  <LandingValuePropCard
                    cardImage={
                      <Image
                        layout="fill"
                        objectFit="contain"
                        src="/assets/images/landingPage/landing-page-value-prop-3.png"
                      />
                    }
                    subtitle={t(`common:landingValueProp-3-subtitle`)}
                    title={t(`common:landingValueProp-3-title`)}
                    valueProp1={t(`common:landingValueProp-3-vp1`)}
                    valueProp2={t(`common:landingValueProp-3-vp2`)}
                    valueProp3={t(`common:landingValueProp-3-vp3`)}
                    variant="dark"
                  />
                </Grid>
                <Grid className={classes.valuePropCard} item xs={isSmallTablet ? 12 : 6}>
                  <LandingValuePropCard
                    cardImage={<CardImage4 height={'100%'} width={'100%'} />}
                    subtitle={t(`common:landingValueProp-4-subtitle`)}
                    title={t(`common:landingValueProp-4-title`)}
                    valueProp1={t(`common:landingValueProp-4-vp1`)}
                    valueProp2={t(`common:landingValueProp-4-vp2`)}
                    valueProp3={t(`common:landingValueProp-4-vp3`)}
                    variant="light"
                  />
                </Grid>
              </Grid>
            </Grid>
            {!isSmallTablet && <Grid className={classes.gridMargin} item xs={1} />}
            {isWide && <Grid item xs={1} />}
          </Grid>
          {/* END BODY TEXT */}

          {/* READY TO TAKE CONTROL? */}
          <Grid
            alignItems="center"
            className={
              !isSmallTablet
                ? classnames(classes.bookADemo, margins.landingPageDemoContainerMargins)
                : classes.bookADemo
            }
            container
            item
            justifyContent="center"
          >
            <LandingPageBookADemo />
          </Grid>

          <Grid alignItems="center" container direction="column">
            <Box className={classes.connectsTextWrap}>
              <Typography
                align="center"
                className={classes.connectsShippersText}
                variant={isMobile || isSmallTablet ? 'h3' : 'h2'}
              >
                {t('common:truxwebName')}{' '}
                <span className={classes.accentMain}>{t('common:connects')}</span>{' '}
                {t('common:shippersSeamlessly')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            alignItems={isSmallTablet ? 'center' : 'stretch'}
            className={classes.splitScreenWrap}
            container
            direction={isSmallTablet ? 'column' : 'row'}
          >
            <Grid className={classes.splitScreen} item xs>
              <ShipperCarrierSplitValueProps
                handleOpenSignup={handleOpenSignup}
                userType={EUserTypeV1.SHIPPER}
              />
            </Grid>
            <Grid className={classes.splitScreen} item xs>
              <ShipperCarrierSplitValueProps
                handleOpenSignup={handleOpenSignup}
                userType={EUserTypeV1.CARRIER}
              />
            </Grid>
            {!isSmallTablet && (
              <Grid container justifyContent="flex-end">
                <Grid className={classes.blueRoad} item>
                  <BlueRoad width={'100%'} />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid className={margins.landingPageMargins} container item>
            {isDesktop && <Grid item xs={1} />}
            <Grid item xs>
              <Box className={classes.freightNeedsToChangeWrap} mb={4} mt={isSmallTablet ? 10 : 8}>
                <Box className={classes.gear}>
                  <Gear height={'100%'} width={'100%'} />
                </Box>
                <Typography color="primary" variant={isTabletOrLower ? 'h3' : 'h2'}>
                  {t('common:freightNeedstoChange')}
                </Typography>
                <Typography color="primary" variant={isTabletOrLower ? 'h3' : 'h2'}>
                  {t('common:truxwebIs')}{' '}
                  <span className={classes.accentDark}>{t('common:changingIt')}</span>
                </Typography>
                <Box mt={isDesktop ? 9 : 4}>
                  <LandingPageValuePropSelector />
                </Box>
                <Grid
                  alignItems={isTabletOrLower ? 'flex-start' : 'center'}
                  className={classes.readyToTakeControl}
                  container
                  item
                  justifyContent={isTabletOrLower ? 'space-between' : 'flex-start'}
                  wrap="nowrap"
                >
                  <Grid
                    alignItems={isTabletOrLower ? 'flex-start' : 'center'}
                    container
                    item
                    justifyContent={isTabletOrLower ? 'space-between' : 'flex-start'}
                    md
                    style={isSmallTablet ? { paddingBottom: 13 } : { paddingBottom: 0 }}
                    wrap={isSmallTablet ? 'wrap' : 'nowrap'}
                    xs={isSmallTablet ? 12 : 8}
                  >
                    <Typography
                      className={classes.readyToTakeControlText}
                      variant={isSmallTablet ? 'h4' : 'h3'}
                    >
                      {' '}
                      {t('common:readyToTakeControl')}{' '}
                    </Typography>
                    {(isTablet || isDesktop) && (
                      <LandingPageButton
                        onClick={handleOpenSignup}
                        text={t('common:signUp')}
                        type="standard"
                      />
                    )}
                  </Grid>

                  {!isTablet && !isDesktop && (
                    <LandingPageButton
                      onClick={handleOpenSignup}
                      text={t('common:signUp')}
                      type="standard"
                    />
                  )}
                </Grid>
              </Box>
            </Grid>
            {isDesktop && <Grid item xs={1} />}
          </Grid>
          <LandingPageStrongNetwork />
          <Testimonials />
          <LandingPageLetsTalk />
        </Grid>
      )}
    </ResponsiveBasePage>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
): Promise<NextPageProps | TNextRedirect> => {
  return await getTruxwebServerSideProps(
    context,
    {
      pageId: PAGE_ID,
      pageLanguage: PAGE_LANGUAGE,
      requiredNamespaces: REQUIRED_NAMESPACES,
    },
    true
  );
};
export default Home;
